/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import p1 from 'assests/Photos/ClassAssembly/3E/3.jpg'
// import p2 from 'assests/Photos/ClassAssembly/3E/1.jpg'
// import p3 from 'assests/Photos/ClassAssembly/3E/2.jpg'

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const Content = () => {
  const theme = useTheme();
 
  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/3E/3.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/3E/1.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/3E/2.webp`;
 

  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant='h4' align='center'>
        Dussera
        </Typography>
        <Typography align='left' component={'p'} color="text.secondary">
        Date: 6 October 2022 & 7 October 2022
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The festival of Dussera is celebrated to remember Lord Rama&#39;s triumph over Ravana and also
commemorate Goddess Durga&#39;s triumph over the demon Mahishasura in protecting the world from his
atrocities.<br/><br/>
The students of Class 3E presented their Assembly on ‘Dussera’ on Thursday, 6 October 2022 and Friday, 7
October 2022 and enthusiastically brought out the essence of this very special day by delivering a
motivating thought for the day which highlighted the importance of the festival.
The students spoke about the victory of good over evil as they enacted a skit in which they were dressed
up like the various characters of the epic – Ramayana which brought out the true spirit of the festival.
The assembly concluded with a message that doing good to others is not a duty but is a feeling of joy.
        </Typography>
        <Box width={1} height={1} marginY={4}>
          <LazyLoadImage
            height={'50%'}
            width={'50%'}
            src={p2}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
          <LazyLoadImage
            height={'50%'}
            width={'50%'}
            src={p3}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
        </Box>
        <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        >
          “Every day when we see the sunrise, it shows us that darkness will always be overcome by light – it is the power of good over evil.”
        </Typography>
        <LazyLoadImage
            height={'100%'}
            width={'100%'}
            src={p1}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
        <Box marginY={4}>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
